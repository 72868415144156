@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");

// themes - our custom or/and out of the box themes
@import "themes";

// framework component themes (styles tied to theme variables)
@import "@nebular/theme/styles/globals";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/grid";

// loading progress bar theme
@import "./pace.theme";

@import "./layout";
@import "./overrides";

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

.content {
  overflow-x: hidden !important;
}

.redstar {
  color: #c9252c;
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
}

.NewLabel.NewLabel {
  color: #8e9bb3;
  width: 93em;
  font-size: 11px;
  font-weight: bold;
  margin-top: 6px;
  text-align: center;
}

table.v2 {
  &.with-image {
    th {
      &:first-child {
        border-bottom: none !important;
        border-right: none !important;
      }

      &:nth-child(2) {
        border-left: none !important;
      }
    }

    td {
      padding: 0.3rem 0.5rem !important;

      &:first-child {
        width: 44px;
        min-width: initial;
        border-right: none !important;
        padding-left: 1rem;
      }

      &:nth-child(2) {
        border-left: none;
      }
    }
  }

  th {
    padding: 0.6rem 0.5rem !important;
    border: 1px solid #edf1f7 !important;
    color: #000000;
    font-size: 13px;

    &:last-child {
      min-width: 130px;
    }
  }

  td {
    font-size: 13px;
    position: relative;
    padding: 0.6rem 0.5rem;
    border: 1px solid #edf1f7;
    vertical-align: middle;
    font-family: Open Sans, sans-serif;
    font-weight: 400;
    line-height: 1.25rem;
    color: #000000;
    min-width: 90px;

    &:last-child {
      min-width: 110px;
    }
  }
}

.actions-header {
  justify-content: space-between;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 50px;

  span {
    line-height: 3.5rem;
    font-size: 0.75rem;
  }
}

.actions-header .tab span {
  line-height: 1rem !important;
}

.pagination.pagination {
  font-family: Open Sans, sans-serif;
  font-size: 0.875rem;
  line-height: 1rem;
  border: 1px solid #edf1f7;
  border-radius: 0.17rem;

  li {
    color: #222b45;
    font-family: Open Sans, sans-serif;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.25rem;
    border-right: 1px solid #edf1f7;
  }

  li > span {
    font-size: 0.875rem;
    line-height: 1rem;
    background-color: transparent;
    color: #36f;
    padding: 0.75rem 1.25rem;
    border: none;
  }

  li > a {
    font-size: 0.875rem;
    line-height: 1rem;
    background-color: transparent;
    padding: 0.7rem 1.25rem;
    border: none;
  }
}

.filter-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #dce2ea;
  padding: 20px 50px 15px 45px;
  width: calc(100% + 70px);
  position: relative;
  left: -35px;
  top: -35px;
  text-align: center;

  button {
    margin: 0.5rem !important;
    margin-left: 0 !important;
  }

  & > div {
    display: flex;
    flex-direction: row;

    &:first-child > span {
      color: #242d46;
      line-height: 3.5rem;
      margin-right: 20px;
      font-size: 13px;
      display: block;

      &:first-child {
        font-size: 20px;
      }

      select {
        outline: none;
        margin-top: 15px;
        background: transparent;
        align-items: center;
        cursor: pointer;
        border: none;
      }
    }

    &:last-child {
      position: relative;
      right: -20px;
    }
  }
}

.card-header-v2 {
  border: none !important;
  padding-bottom: 0 !important;
}

.select-button {
  height: 45px !important;
  box-sizing: border-box !important;
  margin-top: 5px !important;
  margin-bottom: 15px !important;
}

.custom-error {
  position: absolute;
  font-size: 10px;
  color: #ff3d71;
  top: 50px;
}

button {
  max-height: 45px !important;
}

.call-action-cards {
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr) 2fr;
}

.call-action-cards nb-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.call-action-label {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-left: 15px;

  h6 {
    margin: 0 !important;
  }
}

.call-action-value {
  width: 72px;
  height: 72px;
  background-color: #30a2ff;
  border-radius: 3px;
  margin: 10px;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  color: white;
}

.call-action-cards nb-card:last-child .call-action-value {
  width: 55%;
  background: #fe6889;
}

.selected .select-button.placeholder {
  color: #000 !important;
}

.improved-slider {
  .slide-out-container {
    padding-top: 145px !important;
  }

  nb-icon.show-hide-toggle {
    top: 100px !important;
  }
}

.row div nav {
  text-align: center;
}

#records {
  font-size: 12px;
  color: var(--primary);
}

.nb-theme-default .slide-out-container {
  width: 25% !important;
}

.nb-theme-default .slide-out-container.expanded {
  left: calc(100% + 1px - 25%) !important;
}

.nb-theme-default .slide-out-container::before {
  opacity: 1 !important;
  box-shadow: none !important;
}

.nb-theme-default .echart.pie {
  position: absolute;
  width: 100% !important;
  height: 300px !important;
  padding: 15px !important;
}

.slide-out-container {
  overflow: auto !important;
  background: #f6f9fc !important;
  opacity: 1 !important;
  z-index: 10 !important;
}

.custom-smart table tr th,
.custom-smart table tr td {
  padding: 15px !important;
}

.custom-smart thead tr {
  background: #f6f9fc !important;
}

.custom-smart tbody td {
  background: #fff !important;
}

.tooltip-container {
  position: relative;
  display: inline-block;

}

.tooltip-container .tooltiptext {
  visibility: hidden;
  color: #000;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  background-color: #e8eaec;

    position: absolute;
  z-index:9999999999999999 !important;

  width: 120px;
  bottom: 115%;
  left: 50%;
  margin-left: -60px;
  box-shadow: 0 10px 6px -6px #7777777a;
}

.tooltip-container:hover .tooltiptext {
  visibility: visible;
}

.echart.customized {
  height: 350px;
  padding: 5%;
}

.echart.customized-special {
  padding: 0%;
}

nb-calendar nb-card {
  width: auto !important;
}

nb-tabset.todos {
  ul.tabset {
    width: 300px !important;
    border-bottom: none !important;
  }

  .tab > a {
    color: #ffc7c7 !important;
  }

  .tab.active > a {
    color: white !important;

    &:before {
      background-color: #fff !important;
    }
  }
}

*[bright] ul {
  background-color: #fff;
}
