.p-dropdown{
  width: 100%;
  height: 2.5rem;
font-size: 1.1em;
}

p-dropdown{
width: 100%;
height: 2.5rem;
padding-bottom: 0rem;
font-size: 1.1em;
}

.p-dropdown-filter-container {
  font-size: 1.1em;
}

.p-dropdown .p-dropdown-label {
  font-size: 1.1em ;
}
