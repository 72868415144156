.p-inputnumber {
    width: 100%;
    height: 2.5rem;
    font-size: 1.1em;
}

p-inputnumber {
    width: 100%;
    height: 2.5rem;
    font-size: 1.1em;
}


.p-inputnumber-input {
    width: 100%;
    font-size: 1.1em;

}
